<template>
  <div class="grid-base-menu" v-if="clubVersion === 'v2'">
    <MenuSlide @changeType="changeType" :feature_access_free="feature_access_free" />
    <Configuracoes v-if="haveBeenCalled.geral" v-show="type === 'geral'" />
    <Custom v-if="haveBeenCalled.custom" v-show="type === 'custom'" />
    <MeusPlanos class="pr-3" v-if="haveBeenCalled.plans"  v-show="type === 'plans'" />
    <Avancado class="pr-3" v-if="haveBeenCalled.advanced"  v-show="type === 'advanced'" />
    <CamposPersonalizados class="pr-3" v-if="haveBeenCalled.custom_camp"  v-show="type === 'custom_camp'" />
    <AcessoGratuito class="pr-3" v-if="haveBeenCalled.free"  v-show="type === 'free'"/>
    <MinhaConta class="pr-3"  v-if="haveBeenCalled.account" v-show="type === 'account'" />
    <Integration class="pr-3" v-if="haveBeenCalled.integration"  v-show="type === 'integration'" />
  </div>
  <div class="grid-base-menu" v-else>
    <MenuSlide @changeType="changeType" :feature_access_free="feature_access_free" />
    <Configuracoes v-if="haveBeenCalled.geral" v-show="type === 'geral'" />
    <Custom v-if="haveBeenCalled.custom" v-show="type === 'custom'" />
    <MeusPlanos class="pr-3" v-if="haveBeenCalled.plans"  v-show="type === 'plans'" />
    <Avancado class="pr-3" v-if="haveBeenCalled.advanced"  v-show="type === 'advanced'" />
    <CamposPersonalizados class="pr-3" v-if="haveBeenCalled.custom_camp"  v-show="type === 'custom_camp'" />
    <AcessoGratuito class="pr-3" v-if="haveBeenCalled.free"  v-show="type === 'free'"/>
    <MinhaConta class="pr-3"  v-if="haveBeenCalled.account" v-show="type === 'account'" />
    <Integration class="pr-3" v-if="haveBeenCalled.integration"  v-show="type === 'integration'" />
  </div>
</template>

<script>
import Cookies from "js-cookie"

export default {
  components: {
    MenuSlide: () => import('@/components/configs/MenuSlide.vue'),
    Configuracoes: () => import('@/components/configs/Configuracoes.vue'),
    Custom: () => import('@/components/configs/Custom.vue'),
    Avancado: () => import('@/components/configs/Avancado.vue'),
    MeusPlanos: () => import('@/components/MeusPlanos.vue'),
    MinhaConta: () => import('@/components/configs/MinhaConta.vue'),
    Integration: () => import('@/components/configs/Integration.vue'),
    CamposPersonalizados: () => import('@/components/configs/CamposPersonalizados.vue'),
    AcessoGratuito: () => import('@/components/AcessoGratuito.vue'),
  },
  data() {
    return {
      type: "geral",
      color: "",
      theme: "light",
      themeColor: "Claro",
      feature_access_free: false,
      haveBeenCalled: { },
    };
  },
  computed: {
    themeComputed() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  mounted() {
    this.getColor();
    this.getThemeFixed();
    this.getFreeCourses();
  },
  watch: {
    type: {
      handler(newVal) {      
        this.haveBeenCalled[newVal] = true;
      },
      immediate: true
    }
  },
  methods: {
    changeType(type) {
      this.type = type;
    },
    insertTheme() {
      const d = document;
      var theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light");
        if (document.getElementById("slider")) {
          document.getElementById("slider").checked = false;
        }
      } else {
        d.documentElement.setAttribute("data-theme", theme);
        if (theme === "light") {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = false;
          }
        } else {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = true;
          }
        }
      }
      this.$root.$emit("loadTheme")
      this.$root.$emit('loadsettheme')
    },
    async getFreeCourses() {
      let resp = await this.$store.dispatch("fetchMetas", [
        "feature_access_free"
      ])
      if (resp.feature_access_free === 'on' ) {
        this.feature_access_free = true;
      } else {
        this.feature_access_free = false;
      }
    },
    async getThemeFixed() {
      let resp = await this.$store.dispatch("fetchMetas", [
        "theme_fixed_painel"
      ])
        if (resp.theme_fixed_painel === "on") {
          this.getThemeFixedColor()
        } else {
          this.insertTheme()
        }
    },
    async getThemeFixedColor() {
      let resp = await this.$store.dispatch("fetchMetas", [
        "theme_fixed_painel_color"
      ])
        const d = document;
        if (resp.theme_fixed_painel_color === "light") {
          Cookies.set("themeClub", "light", { expires: 365 })
          this.theme = "light"
          this.themeColor = "Claro"
        } else {
          Cookies.set("themeClub", "dark", { expires: 365 })
          this.theme = "dark"
          this.themeColor = "Escuro"
        }
        this.existColorFixed = true
        d.documentElement.setAttribute("data-theme", this.theme)
        this.$root.$emit("loadTheme")
        this.$root.$emit("loadsettheme")
    },
    async getColor() {
      let resp = await this.$store.dispatch("fetchMetas", [
        "main_color"
      ])
      this.color = resp.main_color;
      if (this.color !== null) {
        this.$root.$emit("changedmaincolor", this.color);
        const d = document;
        d.documentElement.setAttribute("data-color", this.color)
        document.documentElement.style.setProperty("--maincolor", this.color)
        document.documentElement.style.setProperty(
          "--maincolorn",
          this.$func.convertColor(this.color, 210)
        )
        document.documentElement.style.setProperty(
          "--maincolortrans",
          this.color + "0f"
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grid-base-menu {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 115px;
  margin-left: 50px;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .grid-base-menu {
    grid-template-columns: 1fr;
    margin-left: 0px;
    padding: 0px 5px;
    display: block;
  }
  .base {
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
  }
  .gridColum {
    grid-template-columns: 1fr !important;
  }
}
</style>